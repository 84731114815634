.cards {
  padding: 4rem;
  background: #fff;
}

.noinfo{
  font-size: 24px;
  color:#838799;
}

.blogs{
  height: 500px;
}

.card-container {
  background-color: #fff;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-payment {
  background-color: #fff;
  padding: 4rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-v2 {
  background-color: #fff;
  padding: 4rem 0 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-mitra-section {
  background-color: #00ADD8;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-blog {
  background-color: #F9F9FB;
  padding: 4rem 0 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-links-form {
  width: 100%;
  max-width: 1250px;
  display: flex;
  margin-top: 20px;
  padding-left: 70px;
}

.card-link-items-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 20px 20px 150px;
  text-align: left;
  box-sizing: border-box;
  min-width: 400px;
}

.card-container-form {
  background-color: #F9F9FB;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.card-text-form {
  color: #3E414C;
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: left;
  margin-left: 220px;
  font-size: 18px;
  line-height: 24.52px;
}

.card-title-form {
  color: #3E414C;
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: left;
  margin-left: 220px;
  padding-bottom: 20px;
  font-size: 38px;
  line-height: 51.76px;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

.form-input {
  color: #838799;
  border: 0;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0 20px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  width: 600px; 
}

.form-input-textarea {
  height: 140px;
}

.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-text-blog {
  font-size: 38px;
  margin-top: 25px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: left;
}

.card-title-mitra {
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: left;
  margin-left: 5px;
  margin-bottom: 10px;
}

.card-title-border {
  width: 100%;
  max-width: 50px;
  display: flex;
  border: 4px solid #E12029; 
  border-radius: 8px;
  margin-left: 5px;
  margin-bottom: 20px;
}

.merchant > .card-title-border{
  margin-bottom: 60px;
}

.merchant > .card-title-blank{
  margin-bottom: 36px;
}

.card-title-blank {
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: left;
  margin-left: 5px;
  padding-bottom: 50px;
}

.card-title-kontak {
  color: #3E414C;
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: left;
  margin-left: 150px;
  padding-bottom: 20px;
  font-size: 38px;
  line-height: 51.76px;
}

.card-text-kontak {
  color: #3E414C;
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: left;
  margin-left: 150px;
  padding-bottom: 50px;
  font-size: 18px;
  line-height: 24.52px;
}

.card-links {
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: center;
}

.card-links-blog {
  width: 100%;
  max-width: 1250px;
  display: flex;
}

.card-links-mitra {
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: center;
}

.card-link-wrapper {
  display: flex;
}

.card-link-wrapper-mitra {
  display: flex;
}

.card-link-wrapper-why {
  display: flex;
}

.card-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 20px 20px 80px;
  text-align: left;
  box-sizing: border-box;
}

.card-link-items-mitra {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 20px 20px 20px;
  text-align: left;
  box-sizing: border-box;
}

.card-link-items-kontak {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 20px 20px 80px;
  text-align: left;
  box-sizing: border-box;
  min-width: 400px;
}

.card-link-items-pengguna {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 20px 20px 80px;
  text-align: left;
  box-sizing: border-box;
  min-width: 360px;
}

.card-link-items-payment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 20px 0 80px;
  text-align: left;
  box-sizing: border-box;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__container__v2 {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120;
  width: 90%;
  margin: 0 auto;
}

.cards__container__v3 {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  /* background-color: #1f98f4; */
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.card__item__image__center {
  justify-content: center;
}

/* V2 */
.cards__item__v2 {
  display: flex;
  flex: 1;
  margin: 2rem 1rem;
  border-radius: 10px;
}

.cards__item__link-v2 {
  display: flex;
  flex-flow: row;
  width: 400px;
  max-height: 200px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 36px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap-v2 {
  width: 100%;
  overflow: hidden;
  min-width: 160px;
  min-height: 200px;
  max-width: 160px;
  max-height: 200px;
}

.cards__item__pic-wrap-v2::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  /* background-color: #1f98f4; */
  box-sizing: border-box;
}

.cards__item__img-v2 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img-v2:hover {
  transform: scale(1.1);
}
/* V2 */

/* V3 */
.cards__item__v3 {
  display: flex;
  flex: 1;
  margin: 2rem 3rem;
  border-radius: 10px;
}

.cards__item__link-v3 {
  display: flex;
  flex-flow: row;
  width: 500px;
  max-height: 400px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 36px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap-v3 {
  width: 100%;
  overflow: hidden;
  min-width: 260px;
  min-height: 300px;
  max-width: 260px;
  max-height: 300px;
}

.cards__item__pic-wrap-v3::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  /* background-color: #1f98f4; */
  box-sizing: border-box;
}

.cards__item__img-v3 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img-v3:hover {
  transform: scale(1.1);
}
/* V3 */

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

.cards__item__text__pengguna {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 50px;
}

.cards__item__name {
  color: #3E414C;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.cards__item__stat {
  color: #838799;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.cards__item__testi {
  margin-top: 20px;
  color: #3E414C;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.card-title {
  color: #3E414C;
  margin-bottom: 30px;
}

.card-title-white {
  color: #fff;
  margin-bottom: 30px;
}

.card-container-mitra {
  background-color: #F9F9FB;
  padding: 4rem 0 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-payments {
  background: url('/images/homepage/layanan/resident/payments.png') center center/cover no-repeat;
  padding: 10rem 0 4rem 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-separator {
  background: url('/images/homepage/mitra/bg_1.png') center center/cover no-repeat;
  padding: 4rem 0 4rem 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-testimoni {
  background-color: #F9F9FB;
  padding: 4rem 0 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-why {
  background: url('/images/homepage/whySection/bg-mengapa-yipy.png') center center/cover no-repeat;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-link-items-mitra {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  text-align: left;
  box-sizing: border-box;
}

.card-link-items-testimoni {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  text-align: left;
  box-sizing: border-box;
}

.card-link-items-why {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  text-align: center;
  box-sizing: border-box;
}

.more-info-title {
  color: #3E414C;
  font-size: 38px;
  padding-top: 25px;
  font-weight: 700;
}

.more-info-title-white {
  color: #fff;
  font-size: 38px;
  padding-top: 25px;
  font-weight: 700;
}

.more-info-title-mitra {
  color: #3E414C;
  font-size: 24px;
  padding-top: 25px;
  font-weight: 700;
  margin-left: 0;
  margin-bottom: 0;
}

.more-info-text {
  color: #3E414C;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-right: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.more-info-text-white {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-right: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.more-info-text-content {
  color: #3E414C;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-right: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.more-info-text-kontak {
  color: #838799;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.07px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.more-info-ico-kontak {
  color: #E12029;
  font-size: 26px;
  font-weight: 500;
  line-height: 19.07px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.more-info-text-ico-kontak {
  color: #E12029;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.07px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 14px;
  min-width: 200px;
}

.more-info-text-ico-kontak > a{
  text-decoration: none;
  color: #E12029;
}

.info-text-location-kontak {
  color: #3E414C;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.07px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 14px;
  min-width: 200px;
}

.info-text-location-desc-kontak {
  color: #3E414C;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.07px;
  margin-left: 34px;
  margin-right: 80px;
  min-width: 200px;
  max-width: 200px;
}

.map{
  margin-top:8px;
}

.map > a {
  color:#E12029;
  text-decoration: none;
}

.more-info-text-content-list {
  padding-bottom: 20px;
  padding-right: 100px;
  font-weight: 500;
  align-content: "center";
  display: "flex";
}

.more-info-text-content-img {
  width: 32px;
  height: 30px;
  margin-right: 20px;
  margin-top: 6px;
}

.more-info-text-content-mitra {
  color: #3E414C;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 10px;
  margin-right: 60px;
}

.card-image-right {
  margin-top: 50px;
}

.form_label {
  color: #3E414C;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }

  .cards__items {
    display: flex;
  }
}

/* @media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
} */

@media only screen and (max-width: 1024px) {
  .cards__item {
    display: flex;
  }

  .card-link-wrapper {
    flex-direction: column;
    margin: 0 50px 0 50px;
  }

  .card-link-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    box-sizing: border-box;
  }

  .card-link-wrapper-mitra {
    flex-direction: column;
    margin: 0 50px 0 50px;
  }

  .card-link-wrapper-why {
    flex-direction: column;
    margin: 0 50px 0 50px;
  }

}

@media only screen and (max-width: 1080px) {
  .cards__item {
    margin-bottom: 2rem;
    display: column;
  }

  .card-link-wrapper-why {
    flex-direction: column;
    margin: 0 50px 0 50px;
  }

}

@media screen and (max-width: 820px) {
  .card-links {
    padding-top: 2rem;
  }

  .card-container-payment {
    margin-bottom: 80px;
  }

  .card-link-wrapper {
    flex-direction: column;
    margin: 0 50px 0 50px;
  }

  .card-link-wrapper-mitra {
    flex-direction: column;
    margin: 0 50px 0 50px;
  }

  .card-link-wrapper-why {
    flex-direction: column;
    margin: 0 50px 0 50px;
  }

}

@media screen and (max-width: 768px) {
}

.font-red{
  color:#e12029;
}