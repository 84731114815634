video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  background: url('/images/homepage/bg-homepage.png') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  margin-left: 160px;
  max-width: 600px;
  line-height: 63px;
}

.hero-container > p {
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  margin-left: 160px;
  max-width: 550px;
  line-height: 26px;
  text-align: inherit;
}

/* Layanan Resident */
.hero-container-layanan-resident {
  background: url('/images/homepage/layanan/resident/bg.png') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  object-fit: contain;
}

.hero-container-layanan-resident > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  margin-left: 160px;
  max-width: 700px;
  line-height: 63px;
}

.hero-container-layanan-resident > p {
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  margin-left: 160px;
  max-width: 550px;
  line-height: 22px;
}
/* Layanan Resident */

/* Layanan Merchant */
.hero-container-layanan-merchant {
  background: linear-gradient(to right, rgba(0,0,0,.4), rgba(0,0,0,.35), rgba(0,0,0,.0)), url('/images/homepage/layanan/merchant/bg.png') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  object-fit: contain;
}

.hero-container-layanan-merchant > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  margin-left: 160px;
  max-width: 800px;
  line-height: 63px;
}

.hero-container-layanan-merchant > p {
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  margin-left: 160px;
  max-width: 550px;
  line-height: 22px;
}
/* Layanan Merchant */

/* Layanan Staff */
.hero-container-layanan-staff {
  background: linear-gradient(to right, rgba(0,0,0,.4), rgba(0,0,0,.35), rgba(0,0,0,.0)), url('/images/homepage/layanan/staff/bg.png') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  object-fit: contain;
}

.hero-container-layanan-staff > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  margin-left: 160px;
  max-width: 700px;
  line-height: 63px;
}

.hero-container-layanan-staff > p {
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  margin-left: 160px;
  max-width: 550px;
  line-height: 22px;
}
/* Layanan Staff */

/* Mitra Building */
.hero-container-mitra-building {
  background: linear-gradient(to right, rgba(0,0,0,.4), rgba(0,0,0,.35), rgba(0,0,0,.0)), url('/images/homepage/mitra/building/bg.png') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  object-fit: contain;
}

.hero-container-mitra-building > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  margin-left: 160px;
  max-width: 700px;
  line-height: 63px;
}

.hero-container-mitra-building > p {
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  margin-left: 160px;
  max-width: 550px;
  line-height: 22px;
}
/* Mitra Building */

/* Mitra Merchant */
.hero-container-mitra-merchant {
  background: linear-gradient(to right, rgba(0,0,0,.4), rgba(0,0,0,.35), rgba(0,0,0,.0)), url('/images/homepage/mitra/merchant/bg.png') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  object-fit: contain;
}

.hero-container-mitra-merchant > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  margin-left: 160px;
  max-width: 700px;
  line-height: 63px;
}

.hero-container-mitra-merchant > p {
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  margin-left: 160px;
  max-width: 550px;
  line-height: 22px;
}
/* Mitra Merchant */

/* Blog */
.hero-container-blog {
  background: url('/images/homepage/blog/bg.png') center center/cover no-repeat;
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.hero-container-blog > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  line-height: 63px;
}

.hero-container-blog > p {
  margin-top: 50px;
  color: #fff;
  line-height: 22px;
}
/* Blog */

/* Promo */
.hero-container-promo {
  background: url('/images/homepage/promo/bg.png') center center/cover no-repeat;
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.hero-container-promo > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  line-height: 63px;
}

.hero-container-promo > p {
  margin-top: 50px;
  color: #fff;
  line-height: 22px;
}
/* Promo */

/* Pengguna */
.hero-container-pengguna {
  background: linear-gradient(to right, rgba(225, 32, 41, .50), rgba(225, 32, 41, .50)), url('/images/homepage/pengguna/bg.png') center center/cover no-repeat;
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.hero-container-pengguna > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  line-height: 63px;
}

.hero-container-pengguna > p {
  margin-top: 50px;
  color: #fff;
  line-height: 22px;
}
/* Pengguna */

/* Kontak */
.hero-container-kontak {
  background: url('/images/homepage/kontak/bg.png') center center/cover no-repeat;
  height: 560px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.hero-container-kontak > h1 {
  color: #fff;
  font-size: 46px;
  text-align: center;
  line-height: 63px;
}

.hero-container-kontak > p {
  margin-top: 50px;
  color: #fff;
  line-height: 22px;
}
/* Kontak */

/* Download */
.hero-container-download {
  background: url('/images/homepage/download/bg.png') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  object-fit: contain;
}

.hero-container-download > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  margin-left: 160px;
  max-width: 400px;
  line-height: 63px;
}

.hero-container-download > p {
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  margin-left: 160px;
  max-width: 300px;
  line-height: 22px;
}
/* Download */

.hero-btns-normal {
  margin-top: 32px;
}

.hero-btns {
  margin-top: 32px;
  margin-left: 160px;
}

.hero-btns .btn {
  margin: 6px;
  font-size: 16px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 1080px) {
  .hero-container > h1 {
    font-size: 36px;
    margin-left: 50px;
    max-width: 500px;
    line-height: 63px;
  }
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 16px;
    margin-left: 50px;
    max-width: 500px;
    line-height: 26px;
    text-align: inherit;
  }
  .hero-btns {
    margin-top: 32px;
    margin-left: 50px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 36px;
    margin-left: 50px;
    max-width: 400px;
    line-height: 63px;
  }

  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 16px;
    margin-left: 50px;
    max-width: 400px;
    line-height: 26px;
    text-align: inherit;
  }
  
  .hero-btns {
    margin-top: 32px;
    margin-left: 45px;
    width: 200px;
  }
}
