.coming-soon {
    background: url('https://yipy-assets.s3.ap-southeast-1.amazonaws.com/Assets+Website+Yipy.id/under-constructions.png') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    object-fit: contain;
  }
  
  .coming-soon > h1 {
    color: #fff;
    font-size: 46px;
    text-align: left;
    margin-left: 160px;
    max-width: 600px;
    line-height: 63px;
  }
  
  .coming-soon > p {
    margin-top: 30px;
    color: #fff;
    font-size: 16px;
    margin-left: 160px;
    max-width: 550px;
    line-height: 22px;
  }

  .coming-soon-btns {
    text-align: left;
    text-align: -moz-left;
    margin-top: 32px;
  }
  
  .coming-soon-btns .btn {
    margin-top: 40px;
  }

  .content { 
    padding-top: 32px;
  }

  .container-box { 
    position: absolute;
    width: 50%;
    right: 4%;
    padding: 20px;
  }

  .outer-box{
    text-align: center;
    text-align: -moz-center;
  }

  .box{
    width: 80%;
  }

  .box > h1 {
    font-size: 65px!important;
    padding: 16px 0;
  }

  .box > h1, .content > p {
    color:white;
    font-size: 23px;
    padding: 4px 0;
  }

  .box:before, .box:after {
    content: "";
    height: 8px;
    background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(147,147,147,1) 50%, rgba(0,0,0,0) 100%);
    background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(147,147,147,1) 50%, rgba(0,0,0,0) 100%);
    background: -o-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(147,147,147,1) 50%, rgba(0,0,0,0) 100%);
    background: linear-gradient(to right, rgb(255 255 255 / 45%) 0%, rgb(147 147 147 / 0%) 50%, rgb(255 255 255 / 45%) 100%);

    display: block;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .coming-soon-btns > .nav-links:hover {
    border-bottom: none !important;
  }

  .social-icons{
    margin-top: 40px;
  }