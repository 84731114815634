@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700&display=swap);
:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 50px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: #fff;
  color: #E12029;
  border: 1px solid #E12029;
  margin-bottom: 8px;
}

.btn--secondary {
  background-color: #E12029;
  color: #fff;
  border: 1px solid #E12029;
  margin-bottom: 8px;
}

.btn--tersier {
  background-color: #0387A4;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #0387A4;
  transition: all 0.3s ease-out;
  margin-bottom: 8px;
}

.btn--quartet {
  background-color: #FDE9EC;
  color: #E12029;
  padding: 8px 20px;
  border: 1px solid #FDE9EC;
  transition: all 0.3s ease-out;
  margin-bottom: 8px;
}

.btn--secondary--outline {
  background-color: transparent;
  color: #E12029;
  border: 1px solid #E12029;
  margin-right: 10px;
  margin-bottom: 8px;
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
  margin-bottom: 8px;
}

.btn--small {
  padding: 12px 26px;
  font-size: 14px;
}

.btn--medium {
  padding: 12px 26px;
  font-size: 16px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.small {
  font-weight: 400;
}

.normal {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.bolder {
  font-weight: 700;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #E12029;
  transition: 250ms;
}

.btn--primary:hover,
.btn--outline:hover,
.btn--quartet:hover,
.btn--secondary--outline:hover {
  transition: all 0.3s ease-out;
  background: #E12029;
  color: #fff;
  transition: 250ms;
  border: 1px white solid;
}
.navbar {
  background: rgba(62, 65, 76, 0.5);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
  border-bottom: 1px solid #fff;
}

.navbar-logo {
  color: #E12029;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 75vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
  font-weight: 600;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-size: 14px;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #E12029;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}



/* dropdown */
/* The dropdown container */
.dropdown {
  /* float: left; */
  overflow: hidden;
}

/* Dropdown button */
.dropdown .dropbtn {
  display: flex;
  align-items: center;
  height: 80px;
  font-size: 14px;
  border: none;
  color: #fff;
  padding: 14px 16px;
  font-weight: 600;
  background-color: inherit;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 240px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  /* float: none; */
  color: #E12029;
  padding: 14px 16px;
  text-decoration: none;
  font-weight: 600;
  display: block;
  text-align: left;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
/* dropdown */



@media screen and (max-width: 1080px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .dropdown-content{
    text-align: center;
    width: 100%;
    border-radius: 0;
    display: none;
  }

  .dropdown-content a{
    text-align: center;
  }

  .dropdown .dropbtn {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #E12029;
    color: #fff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(25%, 50%);
            transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #E12029;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #E12029;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.BlogContainer {
  /* background-color: black; */
  display: flex;
  flex: 1 1;
  border-radius: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  max-width: 360px;
}

.PenggunaContainer {
  /* background-color: black; */
  display: flex;
  flex: 1 1;
  padding: 20px 40px 20px 40px;
  border-radius: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  min-width: 360px;
  min-height: 400px;
  max-width: 360px;
  max-height: 400px;
}

.MitraContainer {
  /* background-color: black; */
  display: flex;
  flex: 1 1;
  border-radius: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  min-width: 260px;
  min-height: 380px;
  max-width: 260px;
  max-height: 380px;
}

.FormInput {
  border: 0;
  width: 700px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  border-radius: 30px;
  font-size: 18px;
}

.title-blog {
  margin: 20px 0 20px 0;
  font-weight: 700;
  font-size: 26px;
  line-height: 22px;
}

.period-blog {
  margin: 0 0 5px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.date-blog {
  margin: 0 0 25px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #838799;
}

.detail-blog {
  margin: 0 0 25px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #E12029;
}

.row {
  display: flex;
}

.col {
  flex: 50% 1;
}

.download{
  margin-right: 24px;
}

.period-promo {
  margin: 0 0 5px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
}

.date-promo {
  margin: 0 0 25px 0;
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}

.title-mitra {
  margin: 30px 30px 20px 30px;
  font-weight: 700;
  font-size: 18px;
  line-height: 32.69px;
}

.sub-mitra {
  margin: 0 20px 5px 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.cards {
  padding: 4rem;
  background: #fff;
}

.noinfo{
  font-size: 24px;
  color:#838799;
}

.blogs{
  height: 500px;
}

.card-container {
  background-color: #fff;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-payment {
  background-color: #fff;
  padding: 4rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-v2 {
  background-color: #fff;
  padding: 4rem 0 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-mitra-section {
  background-color: #00ADD8;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-blog {
  background-color: #F9F9FB;
  padding: 4rem 0 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-links-form {
  width: 100%;
  max-width: 1250px;
  display: flex;
  margin-top: 20px;
  padding-left: 70px;
}

.card-link-items-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 20px 20px 150px;
  text-align: left;
  box-sizing: border-box;
  min-width: 400px;
}

.card-container-form {
  background-color: #F9F9FB;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.card-text-form {
  color: #3E414C;
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: left;
  margin-left: 220px;
  font-size: 18px;
  line-height: 24.52px;
}

.card-title-form {
  color: #3E414C;
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: left;
  margin-left: 220px;
  padding-bottom: 20px;
  font-size: 38px;
  line-height: 51.76px;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

.form-input {
  color: #838799;
  border: 0;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0 20px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  width: 600px; 
}

.form-input-textarea {
  height: 140px;
}

.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-text-blog {
  font-size: 38px;
  margin-top: 25px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: left;
}

.card-title-mitra {
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: left;
  margin-left: 5px;
  margin-bottom: 10px;
}

.card-title-border {
  width: 100%;
  max-width: 50px;
  display: flex;
  border: 4px solid #E12029; 
  border-radius: 8px;
  margin-left: 5px;
  margin-bottom: 20px;
}

.merchant > .card-title-border{
  margin-bottom: 60px;
}

.merchant > .card-title-blank{
  margin-bottom: 36px;
}

.card-title-blank {
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: left;
  margin-left: 5px;
  padding-bottom: 50px;
}

.card-title-kontak {
  color: #3E414C;
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: left;
  margin-left: 150px;
  padding-bottom: 20px;
  font-size: 38px;
  line-height: 51.76px;
}

.card-text-kontak {
  color: #3E414C;
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: left;
  margin-left: 150px;
  padding-bottom: 50px;
  font-size: 18px;
  line-height: 24.52px;
}

.card-links {
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: center;
}

.card-links-blog {
  width: 100%;
  max-width: 1250px;
  display: flex;
}

.card-links-mitra {
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: center;
}

.card-link-wrapper {
  display: flex;
}

.card-link-wrapper-mitra {
  display: flex;
}

.card-link-wrapper-why {
  display: flex;
}

.card-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 20px 20px 80px;
  text-align: left;
  box-sizing: border-box;
}

.card-link-items-mitra {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 20px 20px 20px;
  text-align: left;
  box-sizing: border-box;
}

.card-link-items-kontak {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 20px 20px 80px;
  text-align: left;
  box-sizing: border-box;
  min-width: 400px;
}

.card-link-items-pengguna {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 20px 20px 80px;
  text-align: left;
  box-sizing: border-box;
  min-width: 360px;
}

.card-link-items-payment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 20px 0 80px;
  text-align: left;
  box-sizing: border-box;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__container__v2 {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120;
  width: 90%;
  margin: 0 auto;
}

.cards__container__v3 {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  -webkit-animation-name: fade-img;
          animation-name: fade-img;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  /* background-color: #1f98f4; */
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.card__item__image__center {
  justify-content: center;
}

/* V2 */
.cards__item__v2 {
  display: flex;
  flex: 1 1;
  margin: 2rem 1rem;
  border-radius: 10px;
}

.cards__item__link-v2 {
  display: flex;
  flex-flow: row;
  width: 400px;
  max-height: 200px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 36px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap-v2 {
  width: 100%;
  overflow: hidden;
  min-width: 160px;
  min-height: 200px;
  max-width: 160px;
  max-height: 200px;
}

.cards__item__pic-wrap-v2::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  /* background-color: #1f98f4; */
  box-sizing: border-box;
}

.cards__item__img-v2 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img-v2:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
/* V2 */

/* V3 */
.cards__item__v3 {
  display: flex;
  flex: 1 1;
  margin: 2rem 3rem;
  border-radius: 10px;
}

.cards__item__link-v3 {
  display: flex;
  flex-flow: row;
  width: 500px;
  max-height: 400px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 36px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap-v3 {
  width: 100%;
  overflow: hidden;
  min-width: 260px;
  min-height: 300px;
  max-width: 260px;
  max-height: 300px;
}

.cards__item__pic-wrap-v3::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  /* background-color: #1f98f4; */
  box-sizing: border-box;
}

.cards__item__img-v3 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img-v3:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
/* V3 */

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

.cards__item__text__pengguna {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 50px;
}

.cards__item__name {
  color: #3E414C;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.cards__item__stat {
  color: #838799;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.cards__item__testi {
  margin-top: 20px;
  color: #3E414C;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.card-title {
  color: #3E414C;
  margin-bottom: 30px;
}

.card-title-white {
  color: #fff;
  margin-bottom: 30px;
}

.card-container-mitra {
  background-color: #F9F9FB;
  padding: 4rem 0 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-payments {
  background: url('/images/homepage/layanan/resident/payments.png') center center/cover no-repeat;
  padding: 10rem 0 4rem 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-separator {
  background: url('/images/homepage/mitra/bg_1.png') center center/cover no-repeat;
  padding: 4rem 0 4rem 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-testimoni {
  background-color: #F9F9FB;
  padding: 4rem 0 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container-why {
  background: url('/images/homepage/whySection/bg-mengapa-yipy.png') center center/cover no-repeat;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-link-items-mitra {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  text-align: left;
  box-sizing: border-box;
}

.card-link-items-testimoni {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  text-align: left;
  box-sizing: border-box;
}

.card-link-items-why {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  text-align: center;
  box-sizing: border-box;
}

.more-info-title {
  color: #3E414C;
  font-size: 38px;
  padding-top: 25px;
  font-weight: 700;
}

.more-info-title-white {
  color: #fff;
  font-size: 38px;
  padding-top: 25px;
  font-weight: 700;
}

.more-info-title-mitra {
  color: #3E414C;
  font-size: 24px;
  padding-top: 25px;
  font-weight: 700;
  margin-left: 0;
  margin-bottom: 0;
}

.more-info-text {
  color: #3E414C;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-right: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.more-info-text-white {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-right: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.more-info-text-content {
  color: #3E414C;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-right: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.more-info-text-kontak {
  color: #838799;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.07px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.more-info-ico-kontak {
  color: #E12029;
  font-size: 26px;
  font-weight: 500;
  line-height: 19.07px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.more-info-text-ico-kontak {
  color: #E12029;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.07px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 14px;
  min-width: 200px;
}

.more-info-text-ico-kontak > a{
  text-decoration: none;
  color: #E12029;
}

.info-text-location-kontak {
  color: #3E414C;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.07px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 14px;
  min-width: 200px;
}

.info-text-location-desc-kontak {
  color: #3E414C;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.07px;
  margin-left: 34px;
  margin-right: 80px;
  min-width: 200px;
  max-width: 200px;
}

.map{
  margin-top:8px;
}

.map > a {
  color:#E12029;
  text-decoration: none;
}

.more-info-text-content-list {
  padding-bottom: 20px;
  padding-right: 100px;
  font-weight: 500;
  align-content: "center";
  display: "flex";
}

.more-info-text-content-img {
  width: 32px;
  height: 30px;
  margin-right: 20px;
  margin-top: 6px;
}

.more-info-text-content-mitra {
  color: #3E414C;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 10px;
  margin-right: 60px;
}

.card-image-right {
  margin-top: 50px;
}

.form_label {
  color: #3E414C;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }

  .cards__items {
    display: flex;
  }
}

/* @media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
} */

@media only screen and (max-width: 1024px) {
  .cards__item {
    display: flex;
  }

  .card-link-wrapper {
    flex-direction: column;
    margin: 0 50px 0 50px;
  }

  .card-link-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    box-sizing: border-box;
  }

  .card-link-wrapper-mitra {
    flex-direction: column;
    margin: 0 50px 0 50px;
  }

  .card-link-wrapper-why {
    flex-direction: column;
    margin: 0 50px 0 50px;
  }

}

@media only screen and (max-width: 1080px) {
  .cards__item {
    margin-bottom: 2rem;
    display: column;
  }

  .card-link-wrapper-why {
    flex-direction: column;
    margin: 0 50px 0 50px;
  }

}

@media screen and (max-width: 820px) {
  .card-links {
    padding-top: 2rem;
  }

  .card-container-payment {
    margin-bottom: 80px;
  }

  .card-link-wrapper {
    flex-direction: column;
    margin: 0 50px 0 50px;
  }

  .card-link-wrapper-mitra {
    flex-direction: column;
    margin: 0 50px 0 50px;
  }

  .card-link-wrapper-why {
    flex-direction: column;
    margin: 0 50px 0 50px;
  }

}

@media screen and (max-width: 768px) {
}

.font-red{
  color:#e12029;
}
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  background: url('/images/homepage/bg-homepage.png') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  margin-left: 160px;
  max-width: 600px;
  line-height: 63px;
}

.hero-container > p {
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  margin-left: 160px;
  max-width: 550px;
  line-height: 26px;
  text-align: inherit;
}

/* Layanan Resident */
.hero-container-layanan-resident {
  background: url('/images/homepage/layanan/resident/bg.png') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  object-fit: contain;
}

.hero-container-layanan-resident > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  margin-left: 160px;
  max-width: 700px;
  line-height: 63px;
}

.hero-container-layanan-resident > p {
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  margin-left: 160px;
  max-width: 550px;
  line-height: 22px;
}
/* Layanan Resident */

/* Layanan Merchant */
.hero-container-layanan-merchant {
  background: linear-gradient(to right, rgba(0,0,0,.4), rgba(0,0,0,.35), rgba(0,0,0,.0)), url('/images/homepage/layanan/merchant/bg.png') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  object-fit: contain;
}

.hero-container-layanan-merchant > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  margin-left: 160px;
  max-width: 800px;
  line-height: 63px;
}

.hero-container-layanan-merchant > p {
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  margin-left: 160px;
  max-width: 550px;
  line-height: 22px;
}
/* Layanan Merchant */

/* Layanan Staff */
.hero-container-layanan-staff {
  background: linear-gradient(to right, rgba(0,0,0,.4), rgba(0,0,0,.35), rgba(0,0,0,.0)), url('/images/homepage/layanan/staff/bg.png') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  object-fit: contain;
}

.hero-container-layanan-staff > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  margin-left: 160px;
  max-width: 700px;
  line-height: 63px;
}

.hero-container-layanan-staff > p {
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  margin-left: 160px;
  max-width: 550px;
  line-height: 22px;
}
/* Layanan Staff */

/* Mitra Building */
.hero-container-mitra-building {
  background: linear-gradient(to right, rgba(0,0,0,.4), rgba(0,0,0,.35), rgba(0,0,0,.0)), url('/images/homepage/mitra/building/bg.png') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  object-fit: contain;
}

.hero-container-mitra-building > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  margin-left: 160px;
  max-width: 700px;
  line-height: 63px;
}

.hero-container-mitra-building > p {
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  margin-left: 160px;
  max-width: 550px;
  line-height: 22px;
}
/* Mitra Building */

/* Mitra Merchant */
.hero-container-mitra-merchant {
  background: linear-gradient(to right, rgba(0,0,0,.4), rgba(0,0,0,.35), rgba(0,0,0,.0)), url('/images/homepage/mitra/merchant/bg.png') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  object-fit: contain;
}

.hero-container-mitra-merchant > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  margin-left: 160px;
  max-width: 700px;
  line-height: 63px;
}

.hero-container-mitra-merchant > p {
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  margin-left: 160px;
  max-width: 550px;
  line-height: 22px;
}
/* Mitra Merchant */

/* Blog */
.hero-container-blog {
  background: url('/images/homepage/blog/bg.png') center center/cover no-repeat;
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.hero-container-blog > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  line-height: 63px;
}

.hero-container-blog > p {
  margin-top: 50px;
  color: #fff;
  line-height: 22px;
}
/* Blog */

/* Promo */
.hero-container-promo {
  background: url('/images/homepage/promo/bg.png') center center/cover no-repeat;
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.hero-container-promo > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  line-height: 63px;
}

.hero-container-promo > p {
  margin-top: 50px;
  color: #fff;
  line-height: 22px;
}
/* Promo */

/* Pengguna */
.hero-container-pengguna {
  background: linear-gradient(to right, rgba(225, 32, 41, .50), rgba(225, 32, 41, .50)), url('/images/homepage/pengguna/bg.png') center center/cover no-repeat;
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.hero-container-pengguna > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  line-height: 63px;
}

.hero-container-pengguna > p {
  margin-top: 50px;
  color: #fff;
  line-height: 22px;
}
/* Pengguna */

/* Kontak */
.hero-container-kontak {
  background: url('/images/homepage/kontak/bg.png') center center/cover no-repeat;
  height: 560px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.hero-container-kontak > h1 {
  color: #fff;
  font-size: 46px;
  text-align: center;
  line-height: 63px;
}

.hero-container-kontak > p {
  margin-top: 50px;
  color: #fff;
  line-height: 22px;
}
/* Kontak */

/* Download */
.hero-container-download {
  background: url('/images/homepage/download/bg.png') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  object-fit: contain;
}

.hero-container-download > h1 {
  color: #fff;
  font-size: 46px;
  text-align: left;
  margin-left: 160px;
  max-width: 400px;
  line-height: 63px;
}

.hero-container-download > p {
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  margin-left: 160px;
  max-width: 300px;
  line-height: 22px;
}
/* Download */

.hero-btns-normal {
  margin-top: 32px;
}

.hero-btns {
  margin-top: 32px;
  margin-left: 160px;
}

.hero-btns .btn {
  margin: 6px;
  font-size: 16px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 1080px) {
  .hero-container > h1 {
    font-size: 36px;
    margin-left: 50px;
    max-width: 500px;
    line-height: 63px;
  }
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 16px;
    margin-left: 50px;
    max-width: 500px;
    line-height: 26px;
    text-align: inherit;
  }
  .hero-btns {
    margin-top: 32px;
    margin-left: 50px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 36px;
    margin-left: 50px;
    max-width: 400px;
    line-height: 63px;
  }

  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 16px;
    margin-left: 50px;
    max-width: 400px;
    line-height: 26px;
    text-align: inherit;
  }
  
  .hero-btns {
    margin-top: 32px;
    margin-left: 45px;
    width: 200px;
  }
}

.footer-container {
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  font-size: 16px;
}

.footer-link-items h2 {
  font-size: 18px;
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
  font-size: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}

.coming-soon {
    background: url('https://yipy-assets.s3.ap-southeast-1.amazonaws.com/Assets+Website+Yipy.id/under-constructions.png') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    object-fit: contain;
  }
  
  .coming-soon > h1 {
    color: #fff;
    font-size: 46px;
    text-align: left;
    margin-left: 160px;
    max-width: 600px;
    line-height: 63px;
  }
  
  .coming-soon > p {
    margin-top: 30px;
    color: #fff;
    font-size: 16px;
    margin-left: 160px;
    max-width: 550px;
    line-height: 22px;
  }

  .coming-soon-btns {
    text-align: left;
    text-align: -moz-left;
    margin-top: 32px;
  }
  
  .coming-soon-btns .btn {
    margin-top: 40px;
  }

  .content { 
    padding-top: 32px;
  }

  .container-box { 
    position: absolute;
    width: 50%;
    right: 4%;
    padding: 20px;
  }

  .outer-box{
    text-align: center;
    text-align: -moz-center;
  }

  .box{
    width: 80%;
  }

  .box > h1 {
    font-size: 65px!important;
    padding: 16px 0;
  }

  .box > h1, .content > p {
    color:white;
    font-size: 23px;
    padding: 4px 0;
  }

  .box:before, .box:after {
    content: "";
    height: 8px;
    background: linear-gradient(to right, rgb(255 255 255 / 45%) 0%, rgb(147 147 147 / 0%) 50%, rgb(255 255 255 / 45%) 100%);

    display: block;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .coming-soon-btns > .nav-links:hover {
    border-bottom: none !important;
  }

  .social-icons{
    margin-top: 40px;
  }
.left-section {
    width: 40%;
    background-color: #f2f2f2;
    background-image: url(https://yipy-assets.s3.ap-southeast-1.amazonaws.com/Web+Delete+Account/bg-deleteaccount.png);
    background-size: cover;
}

.right-section {
    width: 60%;
    background-color: #e0e0e0;
   
}

.container-body {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.vertical-align-content {
    background-color:#ffffff;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
}

.content {
    text-align: center;
}

.content > table > tr > td > p {
    margin: 10px 0;
    font-size: 20px;
}

.content-delete-account{
    display: none;
}

.email{
    font-size: 30px;
    padding: 10px;
    border-radius: 10px;
    border-color: #c3c3c3;
    width: 100%;
}

.button-email{
    font-size: 20px;
    font-weight: bold;
    padding: 15px;
    border-radius: 10px;
    border-color: transparent;
    color: white;
    width: 100%;
    background-color: #E12029;
}

img.deletestaffsuccess {
    width: 6.25rem;
}
.left-section {
    width: 40%;
    background-color: #f2f2f2;
    background-image: url(https://yipy-assets.s3.ap-southeast-1.amazonaws.com/Web+Delete+Account/bg-deleteaccount.png);
    background-size: cover;
}

.right-section {
    width: 60%;
    background-color: #e0e0e0;
   
}

.container-body {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.vertical-align-content {
    background-color:#ffffff;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
}

.content {
    text-align: center;
}

.content > table > tr > td > p {
    margin: 10px 0;
    font-size: 20px;
}

.content-delete-account{
    display: none;
}

.email{
    font-size: 30px;
    padding: 10px;
    border-radius: 10px;
    border-color: #c3c3c3;
    width: 100%;
}

.button-email{
    font-size: 20px;
    font-weight: bold;
    padding: 15px;
    border-radius: 10px;
    border-color: transparent;
    color: white;
    width: 100%;
    background-color: #E12029;
}

img.deletestaffsuccess {
    width: 6.25rem;
}
.left-section {
    width: 40%;
    background-color: #f2f2f2;
    background-image: url(https://yipy-assets.s3.ap-southeast-1.amazonaws.com/Web+Delete+Account/bg-deleteaccount.png);
    background-size: cover;
}

.right-section {
    width: 60%;
    background-color: #e0e0e0;
   
}

.container-body {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.vertical-align-content {
    background-color:#ffffff;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
}

.content {
    text-align: center;
}

.content > table > tr > td > p {
    margin: 10px 0;
    font-size: 20px;
}

.content-delete-account{
    display: none;
}

.email{
    font-size: 30px;
    padding: 10px;
    border-radius: 10px;
    border-color: #c3c3c3;
    width: 100%;
}

.button-email{
    font-size: 20px;
    font-weight: bold;
    padding: 15px;
    border-radius: 10px;
    border-color: transparent;
    color: white;
    width: 100%;
    background-color: #E12029;
}

img.deleteresidentsuccess {
    width: 6.25rem;
}
