:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 50px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: #fff;
  color: #E12029;
  border: 1px solid #E12029;
  margin-bottom: 8px;
}

.btn--secondary {
  background-color: #E12029;
  color: #fff;
  border: 1px solid #E12029;
  margin-bottom: 8px;
}

.btn--tersier {
  background-color: #0387A4;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #0387A4;
  transition: all 0.3s ease-out;
  margin-bottom: 8px;
}

.btn--quartet {
  background-color: #FDE9EC;
  color: #E12029;
  padding: 8px 20px;
  border: 1px solid #FDE9EC;
  transition: all 0.3s ease-out;
  margin-bottom: 8px;
}

.btn--secondary--outline {
  background-color: transparent;
  color: #E12029;
  border: 1px solid #E12029;
  margin-right: 10px;
  margin-bottom: 8px;
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
  margin-bottom: 8px;
}

.btn--small {
  padding: 12px 26px;
  font-size: 14px;
}

.btn--medium {
  padding: 12px 26px;
  font-size: 16px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.small {
  font-weight: 400;
}

.normal {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.bolder {
  font-weight: 700;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #E12029;
  transition: 250ms;
}

.btn--primary:hover,
.btn--outline:hover,
.btn--quartet:hover,
.btn--secondary--outline:hover {
  transition: all 0.3s ease-out;
  background: #E12029;
  color: #fff;
  transition: 250ms;
  border: 1px white solid;
}