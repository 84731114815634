.left-section {
    width: 40%;
    background-color: #f2f2f2;
    background-image: url(https://yipy-assets.s3.ap-southeast-1.amazonaws.com/Web+Delete+Account/bg-deleteaccount.png);
    background-size: cover;
}

.right-section {
    width: 60%;
    background-color: #e0e0e0;
   
}

.container-body {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.vertical-align-content {
    background-color:#ffffff;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
}

.content {
    text-align: center;
}

.content > table > tr > td > p {
    margin: 10px 0;
    font-size: 20px;
}

.content-delete-account{
    display: none;
}

.email{
    font-size: 30px;
    padding: 10px;
    border-radius: 10px;
    border-color: #c3c3c3;
    width: 100%;
}

.button-email{
    font-size: 20px;
    font-weight: bold;
    padding: 15px;
    border-radius: 10px;
    border-color: transparent;
    color: white;
    width: 100%;
    background-color: #E12029;
}

img.deleteresidentsuccess {
    width: 6.25rem;
}