@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.BlogContainer {
  /* background-color: black; */
  display: flex;
  flex: 1;
  border-radius: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  max-width: 360px;
}

.PenggunaContainer {
  /* background-color: black; */
  display: flex;
  flex: 1;
  padding: 20px 40px 20px 40px;
  border-radius: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  min-width: 360px;
  min-height: 400px;
  max-width: 360px;
  max-height: 400px;
}

.MitraContainer {
  /* background-color: black; */
  display: flex;
  flex: 1;
  border-radius: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  min-width: 260px;
  min-height: 380px;
  max-width: 260px;
  max-height: 380px;
}

.FormInput {
  border: 0;
  width: 700px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  border-radius: 30px;
  font-size: 18px;
}

.title-blog {
  margin: 20px 0 20px 0;
  font-weight: 700;
  font-size: 26px;
  line-height: 22px;
}

.period-blog {
  margin: 0 0 5px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.date-blog {
  margin: 0 0 25px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #838799;
}

.detail-blog {
  margin: 0 0 25px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #E12029;
}

.row {
  display: flex;
}

.col {
  flex: 50%;
}

.download{
  margin-right: 24px;
}

.period-promo {
  margin: 0 0 5px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
}

.date-promo {
  margin: 0 0 25px 0;
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}

.title-mitra {
  margin: 30px 30px 20px 30px;
  font-weight: 700;
  font-size: 18px;
  line-height: 32.69px;
}

.sub-mitra {
  margin: 0 20px 5px 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
